import axios from 'axios';

export class Topic{
    get SELECTORS(){
        return {
            flagger: '[data-topic-flagger]',
        }
    }
    get CLASSNAMES(){
        return {
            topicFlagged: 'flagged',
        }
    }
    topic: HTMLElement;
    flagger: HTMLAnchorElement;
    public constructor(element) {
        this.topic = element;
        this.flagger = this.topic.querySelector(this.SELECTORS.flagger);
        if(this.topic){
            this.init();
            console.log(this.topic);
        }
    }

    init(){
        this.listenFlagger();

    }

    listenFlagger(){
        if(!this.flagger){
            return;
        }
        this.flagger.addEventListener('click',(ev:Event)=>{
            ev.preventDefault();
            console.log(this.flagger.href);
            axios.get(this.flagger.href)
            .then(resp=> {
                this.topic.classList.add(this.CLASSNAMES.topicFlagged);
            });
        })
    }
}
