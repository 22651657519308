import $ from "jquery";

export class SkipModalComponent {
    private readonly skipModalSelector = '[data-skip-modal]';
    private readonly skipModal: HTMLElement;
    private readonly openBtnSelector = '[data-skip-open]';
    private readonly openBtn: HTMLElement;
    private readonly closeBtnSelector = '[data-skip-close]';
    private readonly closeBtn: HTMLElement;
    private readonly skipAlwaysSelector = '[data-skip-always]';
    private readonly skipAlways: HTMLElement;
    private readonly skipNowSelector = '[data-skip-now]';
    private readonly skipNow: HTMLElement;
    private readonly carouselSelector = '[data-carousel]';
    private readonly carousel: HTMLElement;
    private readonly registerModalSelector = '[data-register-modal]';
    private readonly registerModal: HTMLElement;
    private readonly loginModalSelector = '[data-login-modal]';
    private readonly loginModal: HTMLElement;
    private readonly showClass = 'show';

    public constructor() {
        this.skipModal = document.querySelector(this.skipModalSelector);
        this.openBtn = document.querySelector(this.openBtnSelector);
        this.closeBtn = document.querySelector(this.closeBtnSelector);
        this.skipAlways = document.querySelector(this.skipAlwaysSelector);
        this.skipNow = document.querySelector(this.skipNowSelector);
        this.carousel = document.querySelector(this.carouselSelector);
        this.registerModal = document.querySelector(this.registerModalSelector);
        this.loginModal = document.querySelector(this.loginModalSelector);
        if (this.skipModal) {
            this.toggleModal();
            this.listenSkip();
        }
    }

    protected toggleModal() {
        this.openBtn.addEventListener('click', () => {
            this.skipModal.classList.add(this.showClass);
        })
        this.closeBtn.addEventListener('click', () => {
            this.skipModal.classList.remove(this.showClass);
        })
    }

    protected listenSkip() {
        this.skipNow.addEventListener('click', () => {
            this.skip();
        })
        this.skipAlways.addEventListener('click', () => {
            this.skip();
            if (this.checkCookie('cookie_policy')) {
                let date = new Date();
                let year = date.getFullYear();
                let expires = (year + 10).toString();
                document.cookie = `skipIntro=true; expires=Tue, 19 Jan ${expires} 04:14:07 GMT`;
            }
        });
    }

    protected checkCookie(name){
        let regex = new RegExp(name);
        let str = decodeURIComponent(document.cookie);
        let split = str.split(';');
        let res = split.findIndex(el => regex.test(el));
        return res > -1;
    }

    public skip() {
        this.skipModal.classList.remove(this.showClass);
        $(this.carousel).carousel(3);
    }
}
