export class LoginVideoComponent {
    protected readonly playBtnsSelector = '[data-video-play]';
    protected readonly playBtns: NodeListOf<HTMLElement>;
    protected readonly videoPopupSelector = '[data-video-popup]';
    protected readonly videoPopup: HTMLElement;
    protected readonly videoSelector = '[data-video]';
    protected readonly video: HTMLMediaElement;
    protected readonly videoCloseSelector = '[data-video-close]';
    protected readonly videoClose: NodeListOf<HTMLElement>;
    protected readonly showClass = 'show';

    public constructor() {
        this.playBtns = document.querySelectorAll(this.playBtnsSelector);
        this.videoPopup = document.querySelector(this.videoPopupSelector);
        this.video = document.querySelector(this.videoSelector);
        this.videoClose = document.querySelectorAll(this.videoCloseSelector);
        if (this.videoPopup) {
            this.addEventListeners();
        }
    }

    protected addEventListeners():void {
        this.playBtns.forEach(btn => {
            btn.addEventListener('click', () => this.openVideo());
        })
        this.videoClose.forEach(btn => {
            btn.addEventListener('click', () => this.closeVideo());
        })
    }

    protected openVideo() {
        this.videoPopup.classList.add(this.showClass);
        this.video.play();
    }

    protected closeVideo() {
        this.video.pause();
        this.videoPopup.classList.remove(this.showClass);
    }
}
