

import axios from 'axios';

import { CourseContentController } from './CourseContentController';

function getFormInputs(form): HTMLInputElement[] {
    return Array.from(form.querySelectorAll('[data-answer-input]'));
}

function getFormAnswers(form): HTMLElement[] {
    return Array.from(form.querySelectorAll('[data-answer]'));
}

function toggleAnswerCorrectClass(elem: HTMLElement, is_correct: boolean) {
    elem.classList.toggle('l-quiz__question__answer--correct', is_correct);
    elem.classList.toggle('l-quiz__question__answer--wrong', !is_correct);

    if(!is_correct){
        elem.dataset.wrongAnswer=''
    }
}

export class QuizController extends CourseContentController {
    root: HTMLElement | null;
    forms: HTMLFormElement[] = [];
    questionAnswered = {};

    constructor() {
        super();
        this.root = document.querySelector('[data-quiz-root]');
        if (!this.root) {
            throw new Error("Can't find root for controller");
        }

        this.forms = Array.from(this.root.querySelectorAll('[data-question-form]'));
        this.forms.forEach(f => this.initForm(f));
        this.trySetCompleted();
    }

    disableForm(form, disable = true) {
        form.dataset.disabled = disable;
        getFormInputs(form).forEach(elem => elem.disabled = disable);
    }

    checkForWrongAnswers(form:HTMLFormElement){
        let inputEls = form.querySelectorAll('[data-answer-input]:checked');

        inputEls.forEach(i => {
            let isWrong = i.closest('[data-wrong-answer]')
            if(isWrong){
                this.enableRepeatQuizButton();
                console.log('Was found at least one wrong answer, repeat quiz btn enabled')
            }
        })
    }
    enableRepeatQuizButton(){
        let btn = document.querySelector('[data-repeat-quiz]');
        if(!btn){
            return;
        }
        btn.classList.remove('hidden');
    }

    initForm(form) {
        // defensive programming: prevent submit & bubbling
        form.addEventListener('submit', e => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });

        const questionId = form.dataset.questionId;
        const isDisabled = form.dataset.disabled === "true";
        const isMultiChoice = Boolean(Number(form.dataset.questionMultiple));
        const prefilledAnswers = JSON.parse(form.dataset.prefilledAnswers || "[]");

        this.questionAnswered[questionId] = false;
        if (isDisabled) {
            // prefill answers
            const inputs = getFormInputs(form);
            const answers = getFormAnswers(form);
            prefilledAnswers.forEach((datum, i) => {
                const {answer, correct_answer} = datum[0];
                inputs[i].checked = Boolean(answer);
                toggleAnswerCorrectClass(answers[i], correct_answer);
            });
            // set answered
            this.questionAnswered[questionId] = true;
        }

        // add event listeners
        if (isMultiChoice) {
            const btn = form.querySelector('[data-submit]');
            btn.addEventListener('click', this.submitFormFactory(form));
        } else {
            form.addEventListener('change', this.submitFormFactory(form))
        }

        this.checkForWrongAnswers(form);

    }

    trySetCompleted() {
        const completed = Object.keys(this.questionAnswered)
            .reduce((acc, k) => acc && this.questionAnswered[k], true);
        if (completed) {
            this.root.dataset.completed = "true";
            this.root.dispatchEvent(new Event('completed'));
        }
    }

    submitFormFactory(form) {
        return (e) => {
            if (form.dataset.disabled === "true") {
                return;
            }

            const inputs = getFormInputs(form);
            const answers = inputs.map(elem => elem.checked);
            axios.post(form.action, {answers})
                .then((res) => this.handleSuccessSubmit(form, res.data))
                .catch((err) => {
                    // if question already answered
                    if (err.response?.status === 422) {
                        return this.handleAlreadyAnswered(form, err.response.data)
                    }
                    throw err;
                });
        };
    }

    handleSuccessSubmit(form, data) {
        if (data.quiz_completed) {
            this.forms.forEach(f => this.disableForm(f));
        }

        this.disableForm(form);

        const qid = form.dataset.questionId;
        this.questionAnswered[qid] = true;
        this.trySetCompleted();

        const correctAnswers = Object.keys(data.correct_answers)
            .sort() // object's keys order is NOT guaranteed
            .reduce((acc, k) => [...acc, Boolean(data.correct_answers[k])], []);

        getFormAnswers(form).forEach((elem, i) => {
            // const input: HTMLInputElement = elem.querySelector("[data-answer-input]");
            const is_correct = correctAnswers[i];
            toggleAnswerCorrectClass(elem, is_correct);
        })
        if(form.dataset.lastQuestion !== undefined){
            console.log('LAST QUESTION ANSWERED')
            this.forms.forEach(f => this.checkForWrongAnswers(f) )
        }
    }

    handleAlreadyAnswered(form, data) {
        console.log(form, data);
    }
}
