export class SliderComponent {
    protected readonly slidersSelector = '[data-slider]';
    protected readonly sliders: NodeListOf<HTMLElement>;

    public constructor() {
        this.sliders = document.querySelectorAll(this.slidersSelector);
        if (this.sliders) {
            this.handleSliders();
        }
    }

    protected handleSliders() {
        let isDown = false;
        let startX;
        let scrollLeft;
        this.sliders.forEach(slider => {
            slider.addEventListener('mousedown', (e) => {
                e.preventDefault();
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX);
                slider.scrollLeft = scrollLeft - walk;
            });
        })
    }
}
