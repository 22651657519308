export class LoginModalComponent {
    private readonly loginModalSelector = '[data-login-modal]';
    private readonly loginModal: HTMLElement;
    private readonly openBtnsSelector = '[data-login-open]';
    private readonly openBtns: NodeListOf<HTMLElement>;
    private readonly closeBtnSelector = '[data-login-close]';
    private readonly closeBtn: HTMLElement;
    private readonly showClass = 'show';
    /* to clean: use events to manage cross components behaviors: */
    private readonly infoModalSelector = '[data-info-modal]';
    private readonly infoModal: HTMLElement;

    public constructor() {
        this.loginModal = document.querySelector(this.loginModalSelector);
        this.infoModal = document.querySelector(this.infoModalSelector);
        this.openBtns = document.querySelectorAll(this.openBtnsSelector);
        this.closeBtn = document.querySelector(this.closeBtnSelector);
        if (this.loginModal) {
            this.listenModal();
        }
    }

    protected listenModal() {
        this.openBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.loginModal.classList.add(this.showClass);
                this.infoModal.classList.remove(this.showClass);
            })
        })
        this.closeBtn.addEventListener('click', () => {
            this.loginModal.classList.remove(this.showClass);
            window.scrollTo(0, 0);
        })
    }
}
