import * as yup from "yup";

interface RegisterValidationSchema {
  name:                  string;
  last_name:             string;
  email:                 string;
  password:              string;
  password_confirmation: string;
}

export class UserAreaController {

  form: HTMLFormElement;
  inputActions: NodeListOf<HTMLElement> | null;
  isAutoValidationEnabled: boolean;
  validationSchema: yup.Schema<RegisterValidationSchema>;

  constructor() {
    console.log('UserAreaController initialized!');
    this.form = document.querySelector('.js-user-area-form');
    this.inputActions = document.querySelectorAll(".js-form-input-action") as NodeListOf<HTMLElement> | null;


    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.isAutoValidationEnabled = false;

    if (this.form) {
      this.addListeners();
      this.validationSchema = this.buildValidationSchema();
    }

    if (this.inputActions) {
      this.inputActions.forEach((element) => {
        element.addEventListener("click", this.togglePassword)
      })
    }
  }

  addListeners() {
    this.form.addEventListener('input', this.handleInput);
    this.form.addEventListener('submit', this.handleSubmit);
  }

  togglePassword(e: Event) {
    let target = e.currentTarget as HTMLElement;
    let input = target.parentNode.querySelector("input") as HTMLInputElement | null;

    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  }

  async handleInput(event: Event): Promise<void> {
    if (this.isAutoValidationEnabled) {
      const errors: [] | yup.ValidationError = await this.handleValidation();
      this.displayValidationResults(errors);
    }
  }

  async handleValidation() {

    return await this.validationSchema.validate({
      name:                  (this.form.elements.namedItem('name') as HTMLInputElement).value,
      last_name:             this.form.last_name.value,
      email:                 this.form.email.value,
      password:              this.form.password.value,
      password_confirmation: this.form.password_confirmation.value,
    }, {
      abortEarly: false,
    }).then(_ => []).catch(e => e.inner);
  }

  displayValidationResults(errors) {
    // @ts-ignore
    Object.keys(this.validationSchema.fields).forEach(key => {
      const input = this.form.querySelector(`[name="${key}"]`);
      const error = errors.find(e => e.path === key);
      const hasError = error !== undefined;
      const errorElement = input.closest(".form-input").querySelector('.js-form-input__error');
      // @ts-ignore
      input.parentNode.classList.toggle('has-error', hasError);

      errorElement.innerHTML = error ? error.errors[0] : '';
      errorElement.classList.toggle("active", error);
    });
  }

  async handleSubmit(event) {
    let errors = [];
    if (!this.isAutoValidationEnabled) {
      this.isAutoValidationEnabled = true;
      errors = await this.handleValidation();
      this.displayValidationResults(errors);
    }

    errors = await this.handleValidation();

    if (errors.length) {
      event.preventDefault();
      errors = await this.handleValidation();
      this.displayValidationResults(errors);

      const firstError = this.form.querySelector('.has-error');
      firstError && firstError.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      this.form.submit();
    }
  }

  buildValidationSchema(): yup.Schema<RegisterValidationSchema> {
    const now = new Date();
    const maxDate = new Date();

    maxDate.setFullYear(now.getFullYear() - 13);

    yup.setLocale({
      mixed: {
        required: params => `Questo campo è obbligatorio!`,
      },
      string: {
        min: params => `Questo campo deve contenere almeno ${params.min} caratteri!`,
        max: params => `Questo campo deve contenere al massimo ${params.max} caratteri!`,
      },
    });

    let schema = yup.object().shape({
      name: yup
        .string()
        .label('Nome')
        .required()
        .min(2)
        .max(255),
      last_name: yup
        .string()
        .label('Cognome')
        .required()
        .min(2)
        .max(255),
      email: yup
        .string()
        .label('E-mail Personale')
        .email()
        .required()
        .max(255),
      password: yup
        .string()
        .label('Password')
        .max(255)
        .matches(/.{8,}|^$/, 'Questo campo deve contenere almeno 8 caratteri!'),
      password_confirmation: yup
        .string()
        .label('Conferma Password')
        .max(255)
        .matches(/.{8,}|^$/, 'Questo campo deve contenere almeno 8 caratteri!')
        .oneOf([yup.ref('password'), null], 'Le tue password non sono identiche.')
    });

    return schema;
  }
}