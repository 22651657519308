import $ from 'jquery';
import './bootstrap';

import { Header } from './components';
import * as controllers from './controllers';

$(document).ready(() => {
  // Global component initialization
  new Header();

  // Page controller initialization
  const currentController = document.body.dataset['controller'];
  new controllers[currentController]();
});
