import {Topic} from '../components/Topic';

export class ForumController {
    get SELECTORS (){
        return {
            topic: '[data-topic]',
        }
    }
    topics: NodeListOf<HTMLElement>;
    public constructor() {
        this.topics = document.querySelectorAll(this.SELECTORS.topic);
        if(this.topics){
            this.init()
        }
    }

    init(){
        this.topics.forEach( topic => new Topic(topic));
    }
}
