import $ from "jquery";

export class LoginCarouselComponent {
    protected readonly carouselSelector = '[data-carousel]';
    protected readonly carousel: HTMLElement;
    protected readonly barItemsSelector = '[data-item]';
    protected readonly barItems: NodeListOf<HTMLElement>;
    protected readonly dotsSelector = '[data-dots]';
    protected readonly dots: HTMLElement;
    protected readonly arrowsSelector = '[data-arrow]';
    protected readonly arrows: NodeListOf<HTMLElement>;
    protected readonly skipBtnSelector = '[data-skip-open]';
    protected readonly skipBtn: HTMLElement;
    protected readonly activeClass = 'item--active';
    protected readonly hiddenClass = 'hide';

    public constructor() {
        this.carousel = document.querySelector(this.carouselSelector);
        this.barItems = document.querySelectorAll(this.barItemsSelector);
        this.dots = document.querySelector(this.dotsSelector);
        this.arrows = document.querySelectorAll(this.arrowsSelector);
        this.skipBtn = document.querySelector(this.skipBtnSelector);
        if (this.carousel && this.barItems.length > 1) {
            this.handleCarousel();
        }
    }

    protected handleCarousel() {
        $(this.carousel).carousel('pause');
        $(this.carousel).on('slide.bs.carousel', (e) => {
            this.barItems.forEach(item => {
                item.classList.remove(this.activeClass);
            })
            let index = e.to;
            if (index == 3) {
                this.dots.classList.add(this.hiddenClass);
                this.skipBtn.classList.add(this.hiddenClass);
                this.arrows.forEach(arrow => {
                    arrow.classList.add(this.hiddenClass);
                })
            } else {
                this.dots.classList.remove(this.hiddenClass);
                this.skipBtn.classList.remove(this.hiddenClass);
                this.arrows.forEach(arrow => {
                    arrow.classList.remove(this.hiddenClass);
                })
            }
            if (index == 1) {
                this.skipBtn.classList.add('gray');
            } else {
                this.skipBtn.classList.remove('gray');
            }
        })
        $(this.carousel).on('slid.bs.carousel', (e) => {
            let index = e.to;
            let activeItem = document.querySelector(`[data-item="${index}"]`);
            activeItem.classList.add(this.activeClass);
        })
        this.barItems.forEach(item => {
            item.addEventListener('click', () => {
                let index = parseInt(item.dataset.item);
                $(this.carousel).carousel(index);
            })
        })
        let isDown;
        let startX;
        this.carousel.addEventListener('mousedown', e => {
            e.preventDefault();
            isDown = true;
            this.carousel.classList.add('active');
            startX = e.pageX;
        });
        this.carousel.addEventListener('mouseup', () => {
            isDown = false;
            this.carousel.classList.remove('active');
        });
        this.carousel.addEventListener('mouseleave', () => {
            isDown = false;
            this.carousel.classList.remove('active');
        });
        this.carousel.addEventListener('mousemove', e => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX;
            if (startX - x > 50) {
                $(this.carousel).carousel('next');
            } else if (startX - x < -50) {
                $(this.carousel).carousel('prev');
            }
        })
    }
}
