import axios, { AxiosResponse } from 'axios';
import Player from '@vimeo/player';

export class CourseContentController {
  protected readonly nextButtonSelector = '.js-btn-next';
  protected readonly nextButton: HTMLAnchorElement;
  protected seconds;

  protected readonly unitId;
  protected readonly contentPosition;
  protected shouldSendTracking = true;
  protected videoPlayed = {};

  protected quiz: HTMLElement;

  public constructor() {
    console.log('CourseContentController initialized!');

    this.nextButton = document.querySelector(this.nextButtonSelector);
    this.seconds = 0;

    this.unitId = (document.querySelector('input[name=unit_id]') as HTMLInputElement).value;
    this.contentPosition = (document.querySelector('input[name=content_position]') as HTMLInputElement).value;

    this.quiz = document.querySelector('[data-quiz-root]');
    if (this.quiz) {
      // Q: WTF?
      // A: QuizController.ts:87
      //    resources/views/components/course/quiz.blade.php:7
      //    resources/views/course-content.blade.php:60
      //    https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
      this.quiz.addEventListener('completed', () => this.tryEnableNextButton());
    }

    const videoIframes = Array.from(document.querySelectorAll('iframe.ql-video'));
    const players = videoIframes.map(e => this.initPlayer(e));

    // slides' videos are skippable
    const slideVideos = Array.from(document.querySelectorAll('.course-slide__video iframe.ql-video'));
    const articlePlayers = players.filter((p: any) => { // fanculo typescript
      return slideVideos.indexOf(p.element) === -1;
    });

    if (this.nextButton) {
      this.nextButton.setAttribute('disabled', '');
      this.nextButton.addEventListener('click', (evt) => this.handleNextButtonClick(evt));
      setInterval(() => this.registerActivity(), 5000);
    }

    this.initVideoPlayed(articlePlayers)
        .then(() => this.tryEnableNextButton());
  }

  protected tryEnableNextButton() {
    if (this.nextButton && this.nextButtonIsEnabled()) {
      this.nextButton.removeAttribute('disabled');
    }
  }

  protected finalQuizCompleted() {
    if (!this.quiz) return true;
    const final = this.quiz.dataset.finalExam === "true";
    const completed = this.quiz.dataset.completed === "true";
    return !final || (final && completed);
  }

  protected initPlayer(iframe) {
    const p = new Player(iframe);
    p.on('timeupdate', this.throttledSendVideoTracking.bind(this, p));
    p.on('ended', this.sendVideoTracking.bind(this, p));
    return p;
  }

  protected initVideoPlayed(players): Promise<void> {
    players.forEach(p => p.on('play', () => this.markVideoPlayed(p)));
    let promises = players.map(p => p.getVideoId()) as Promise<Number>[];
    return (Promise.all(promises) as Promise<number[]>)
        .then(ids => {
          ids.forEach(id => this.videoPlayed[id] = false)
        });
  }

  private allVideoPlayed() {
    return Object.keys(this.videoPlayed)
        .reduce((acc, k) => acc && this.videoPlayed[k], true);
  }

  private markVideoPlayed(p) {
    p.getVideoId().then(id => {
      this.videoPlayed[id] = true;
      this.tryEnableNextButton();
    });
  }

  private throttledSendVideoTracking(player, evt) {
    if (this.shouldSendTracking) {
      this.sendVideoTracking(player, evt);
      this.shouldSendTracking = false;
      setTimeout(() => this.shouldSendTracking = true, 1000);
    }
  };

  private sendVideoTracking(player, {duration, seconds}) {
    Promise.all([
        player.getVideoTitle(),
        player.getVideoId()
    ]).then(([title, id]) => {
      console.log("video tracked")
      axios.post(`/units/${this.unitId}/videotrack/${this.contentPosition}`, {
        time: seconds,
        totalTime: duration,
        videoId: id,
        videoTitle: title,
      });
    });
  }

  protected nextButtonIsEnabled(): boolean {
      return this.allVideoPlayed() && this.finalQuizCompleted();
  }

  protected registerActivity() {
    return axios
        .post(`/units/${this.unitId}/track`, {time: 5})
        .then((response: AxiosResponse) => {
          console.log('tracked');
        })
  }

  protected handleNextButtonClick(event: MouseEvent) {
    if (this.nextButton.href === '') {
      return false;
    }

    event.preventDefault();

    const href = this.nextButton.href;
    const seconds = this.seconds;

    this.nextButton.href = "";
    this.nextButton.innerHTML = "Loading...";

    axios
        .post(`/units/${this.unitId}/content/${this.contentPosition}/complete`, {
          time: seconds
        })
        .then(() => {
          window.location.href = href;
        })
  }
}
