import axios from "axios";

export class PodcastModal {
    public readonly modal: HTMLElement;
    public readonly id: string;
    public readonly unit: string;
    private readonly audioSelector = '[data-audio]';
    private readonly audio: HTMLAudioElement;
    private readonly playerSelector = '[data-player]';
    private readonly player: HTMLElement;
    private readonly totalTimeSelector = '[data-time-total]';
    private readonly totalTimeElement: HTMLElement;
    private totalTime: number;
    private readonly currentTimeSelector = '[data-time-current]';
    private readonly currentTimeElement: HTMLElement;
    private currentTime: number;
    private readonly progressBarSelector = '[data-progress]';
    private readonly progressBar: HTMLElement;
    private readonly progressTotalSelector = '[data-progress-total]';
    private readonly progressTotal: HTMLElement;
    private readonly playBtnSelector = '[data-play]';
    private readonly playBtn: HTMLElement;
    private readonly pauseBtnSelector = '[data-pause]';
    private readonly pauseBtn: HTMLElement;
    private readonly chaptersSelector = '[data-chapter-id]';
    private readonly chapters: NodeListOf<HTMLElement>;
    private readonly stepsSelector = '[data-step-time]';
    private readonly steps: NodeListOf<HTMLElement>;
    private readonly chapterPopupsSelector = '[data-popup-time]';
    private readonly chapterPopups: NodeListOf<HTMLElement>;
    private playingAnimation: any;

    public constructor(compEl) {
        this.modal = compEl;
        this.id = this.modal.dataset.podcast;
        this.unit = this.modal.dataset.unit;
        this.audio = this.modal.querySelector(this.audioSelector);
        this.player = this.modal.querySelector(this.playerSelector);
        this.totalTimeElement = this.modal.querySelector(this.totalTimeSelector);
        this.currentTimeElement = this.modal.querySelector(this.currentTimeSelector);
        this.progressBar = this.modal.querySelector(this.progressBarSelector);
        this.progressTotal = this.modal.querySelector(this.progressTotalSelector);
        this.playBtn = this.modal.querySelector(this.playBtnSelector);
        this.pauseBtn = this.modal.querySelector(this.pauseBtnSelector);
        this.chapters = this.modal.querySelectorAll(this.chaptersSelector);
        this.steps = this.modal.querySelectorAll(this.stepsSelector);
        this.chapterPopups = this.modal.querySelectorAll(this.chapterPopupsSelector);
        this.currentTime = parseInt(this.modal.dataset.resumeTime);
        this.playingAnimation;
        this.handlePause = this.handlePause.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePlaying = this.handlePlaying.bind(this);
    }

    public initValues() {
        this.setTotalTime();
        this.setCurrentTime();
        this.setPlayerSteps();
        this.audio.currentTime = this.currentTime;
    }

    public saveTime() {
        axios.post(`/module/${this.unit}/podcasttrack/${this.id}`, {
            resumeTime: this.currentTime
        })
    }

    public listenEvents() {
        this.playBtn.addEventListener('click', this.handlePlay);
        this.pauseBtn.addEventListener('click', this.handlePause);
        this.progressBar.addEventListener('click', e => this.handleRewind(e));
    }

    public stopEvents() {
        this.handlePause();
        this.playBtn.removeEventListener('click', this.handlePlay);
        this.pauseBtn.removeEventListener('click', this.handlePause);
    }

    protected setTotalTime() {
        this.totalTime = this.audio.duration;
        let totMinutes = Math.floor(this.totalTime / 60);
        let totSeconds = Math.floor(this.totalTime % 60);
        totSeconds < 10
            ? this.totalTimeElement.innerText = ` / ${totMinutes}:0${totSeconds}`
            : this.totalTimeElement.innerText = ` / ${totMinutes}:${totSeconds}`;
    }

    protected setCurrentTime() {
        let currMinutes = Math.floor(this.currentTime / 60);
        let currSeconds = Math.floor(this.currentTime % 60);
        currSeconds < 10
            ? this.currentTimeElement.innerText = `${currMinutes}:0${currSeconds}`
            : this.currentTimeElement.innerText = `${currMinutes}:${currSeconds}`;
        let progress = this.currentTime / this.totalTime * 100;
        this.progressBar.style.width = `${progress}%`;
        this.chapters.forEach(chapter => {
            let startTime = parseInt(chapter.dataset.chapterStart);
            let endTime = parseInt(chapter.dataset.chapterEnd);
            if (!endTime){
                endTime = Math.round(this.totalTime)-1;
            }
            if (endTime < this.currentTime) {
                const completed = parseInt(chapter.dataset.completed);
                const chapterId = parseInt(chapter.dataset.chapterId);
                if (completed == 0) {
                    // complete corresponding chapter
                    axios.post(`/module/${this.unit}/podcasttrack/${this.id}`, {
                        completeChapter: chapterId,
                        resumeTime: this.currentTime
                    })
                }
                chapter.classList.add('completed');
                chapter.classList.remove('current');
                chapter.dataset.completed = '1';
            } else if (startTime < this.currentTime && this.currentTime < endTime) {
                chapter.classList.add('current');
                chapter.classList.remove('completed');
            } else {
                chapter.classList.remove('completed');
                chapter.classList.remove('current');
            }
        })
    }

    protected setPlayerSteps() {
        this.steps.forEach(step => {
            let time = parseInt(step.dataset.stepTime);
            let percentage = 100 / this.totalTime * time;
            step.style.left = `${percentage}%`;
        });
        this.chapterPopups.forEach(popup => {
            let time = parseInt(popup.dataset.popupTime);
            let minutes = Math.floor(time / 60);
            let seconds = Math.floor(time % 60);
            seconds < 10
                ? popup.innerText = `${minutes}:0${seconds}`
                : popup.innerText = `${minutes}:${seconds}`;
        })
    }

    protected handlePlay() {
        this.audio.play();
        this.player.classList.add('playing');
        this.playingAnimation = requestAnimationFrame(this.handlePlaying);
    }

    protected handlePause() {
        this.audio.pause();
        this.player.classList.remove('playing');
        cancelAnimationFrame(this.playingAnimation);
    }

    protected handlePlaying() {
        this.currentTime = this.audio.currentTime;
        this.setCurrentTime();
        this.playingAnimation = requestAnimationFrame(this.handlePlaying);
    }

    protected handleRewind(e) {
        let position = e.offsetX;
        let posPerc = position / this.progressTotal.offsetWidth * 100;
        this.audio.currentTime = this.totalTime / 100 * posPerc;
        this.currentTime = this.audio.currentTime;
        this.setCurrentTime();
    }
}
