export class Header {
  protected readonly menuOpenSelector = '.js-menu-open';
  protected readonly menuCloseSelector = '.js-menu-close';
  protected readonly targetSelector = '.js-header-items';
  protected readonly menuUserSelector = '.js-menu-user';
  protected readonly dropdownSelector = '.js-menu-user .header__top-menu-md__item__user-dropdown';
  protected readonly modulesTogglerSelector = '.js-header-mobile .header__top-menu-sm__item__link';
  protected readonly modulesMenuSelector = '.js-header-mobile';
  protected readonly cookieBarSelector = '[data-component="CookieBar"]';
  protected openToggler: HTMLElement;
  protected closeToggler: HTMLElement;
  protected targetElement: HTMLElement;
  protected userToggler: HTMLElement;
  protected dropdownElement: HTMLElement;
  protected modulesToggler: HTMLElement;
  protected modulesMenu: HTMLElement;
    //Temporary Cookiebar
    protected cookieBar: HTMLElement;

  public constructor() {
    this.openToggler = document.querySelector(this.menuOpenSelector);
    this.closeToggler = document.querySelector(this.menuCloseSelector);
    this.targetElement = document.querySelector(this.targetSelector);
    this.userToggler = document.querySelector(this.menuUserSelector);
    this.dropdownElement = document.querySelector(this.dropdownSelector);
    this.modulesToggler = document.querySelector(this.modulesTogglerSelector);
    this.modulesMenu = document.querySelector(this.modulesMenuSelector);

    //Temporary Cookiebar
      this.cookieBar = document.querySelector(this.cookieBarSelector);

    this.setupListeners();

      //Temporary Cookiebar
      // this.setupCookiebar();
  }

  protected setupListeners(): void {
    this.openToggler && this.openToggler.addEventListener('click', this.handleMenuOpen.bind(this));
    this.closeToggler && this.closeToggler.addEventListener('click', this.handleMenuClose.bind(this));
    this.userToggler && this.userToggler.addEventListener('mouseenter', this.openUserMenu.bind(this));
    this.userToggler && this.userToggler.addEventListener('mouseleave', this.closeUserMenu.bind(this));
    this.modulesToggler && this.modulesToggler.addEventListener('click', this.toggleModulesMenu.bind(this));
  }

  protected setupCookiebar(){
      let cookieName = 'cookie_policy';
      let hiddenClassname = 'hidden';

      if(this.checkCookie(cookieName)){
          this.cookieBar.classList.add(hiddenClassname);
      }else{
          this.cookieBar.classList.remove(hiddenClassname);
      }
      let ctaAccept = this.cookieBar.querySelector('[data-cookie-cta]');
      ctaAccept.addEventListener('click',()=>{
          console.log('cookie policy accept:  setup')
          this.setCookie(cookieName,1);
          this.cookieBar.classList.add(hiddenClassname);
      })
  }

    protected checkCookie(name){
        let regex = new RegExp(name);
        let str = decodeURIComponent(document.cookie);
        let splited = str.split(';');
        console.log(splited);
        let res = splited.findIndex(el => regex.test(el));
        return res > -1;
    }
    protected setCookie(cookie,val){
        document.cookie = `${cookie}=${val};SameSite=Lax;path=/`;
        return true;
    }
  /**
   *
   * @param {MouseEvent} event
   */
  public toggleModulesMenu(event: MouseEvent): void {
    event.preventDefault();
    this.modulesMenu.classList.toggle('header__top-menu-sm__item--closed');
  }

    /**
   *
   * @param {MouseEvent} event
   */
  public closeUserMenu(event: MouseEvent): void {
    event.preventDefault();
    this.dropdownElement.classList.add('header__top-menu-md__item__user-dropdown--closed');
  }

  /**
   *
   * @param {MouseEvent} event
   */
  public openUserMenu(event: MouseEvent): void {
    event.preventDefault();
    this.dropdownElement.classList.remove('header__top-menu-md__item__user-dropdown--closed');
  }

  /**
   * handleMenuOpen
   * @param {MouseEvent} event
   */
  public handleMenuOpen(event: MouseEvent): void {
    event.preventDefault();
    this.targetElement.classList.add('header__top-menu-sm--expanded');
  }

  /**
   * handleMenuClose
   * @param {MouseEvent} event
   */
  public handleMenuClose(event: MouseEvent): void {
    event.preventDefault();
    this.targetElement.classList.remove('header__top-menu-sm--expanded');
  }
}
