export { HomeController }          from './HomeController';
export { LoginController }         from './LoginController';
export { RegisterController }      from './RegisterController';
export { UserAreaController }      from './UserAreaController';
export { QuizController }    from './QuizController';
export { CourseContentController } from './CourseContentController';
export { ForumController } from './ForumController';
export { AdminAreaController } from './AdminAreaController';
export { ProjectController } from './ProjectController';
export { ModulesController } from './ModulesController';
export { LegalsController } from './LegalsController';
