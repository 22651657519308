export class InfoModalComponent {
    private readonly infoModalSelector = '[data-info-modal]';
    private readonly infoModal: HTMLElement;
    private readonly openBtnsSelector = '[data-info-open]';
    private readonly openBtns: NodeListOf<HTMLElement>;
    private readonly closeBtnSelector = '[data-info-close]';
    private readonly closeBtn: HTMLElement;
    private readonly cardSelectSelector = '[data-step-card] select';
    private readonly cardSelect: HTMLSelectElement;
    private readonly accountSelectSelector = '[data-step-account] select';
    private readonly accountSelect: HTMLSelectElement;
    private readonly ctasSelector = '[data-info-cta]';
    private readonly ctas: NodeListOf<HTMLElement>;
    private readonly forgotPasswordSelector = '[data-forgot-password]';
    private readonly forgotPassword: HTMLElement;
    private readonly showClass = 'show';
    /* to clean: use events to manage cross components behaviors: */
    private readonly loginModalSelector = '[data-login-modal]';
    private readonly loginModal: HTMLElement;

    public constructor() {
        this.infoModal = document.querySelector(this.infoModalSelector);
        this.loginModal = document.querySelector(this.loginModalSelector);
        this.openBtns = document.querySelectorAll(this.openBtnsSelector);
        this.closeBtn = document.querySelector(this.closeBtnSelector);
        this.cardSelect = document.querySelector(this.cardSelectSelector);
        this.accountSelect = document.querySelector(this.accountSelectSelector);
        this.ctas = document.querySelectorAll(this.ctasSelector);
        this.forgotPassword = document.querySelector(this.forgotPasswordSelector);
        if (this.infoModal) {
            this.listenModal();
            this.setInitial();
            this.listenSelects();
        }
    }

    protected listenModal() {
        this.openBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.infoModal.classList.add(this.showClass);
                this.loginModal.classList.remove(this.showClass);
            });
        });
        this.closeBtn.addEventListener('click', () => {
            this.infoModal.classList.remove(this.showClass);
            window.scrollTo(0, 0);
        })
    }

    protected setInitial() {
        if (this.cardSelect) {
            this.setStep(0);
        } else {
            this.setStep(1);
        }
    }

    protected listenSelects() {
        if (this.cardSelect) {
            this.cardSelect.addEventListener('change', () => {
                let value = this.cardSelect.value;
                this.setStep(value);
            })
        }
        if (this.accountSelect) {
            this.accountSelect.addEventListener('change', () => {
                let value = this.accountSelect.value;
                this.setStep(value);
            })
        }
    }

    protected setStep(value) {
        this.ctas.forEach(cta => {
            if (cta.dataset.infoCta == value) {
                cta.classList.add(this.showClass);
            } else {
                cta.classList.remove(this.showClass);
            }
        })
    }
}
