export class RegisterModalComponent {
    private readonly registerModalSelector = '[data-register-modal]';
    private readonly registerModal: HTMLElement;
    private readonly openBtnSelector = '[data-register-open]';
    private readonly openBtn: HTMLElement;
    private readonly closeBtnSelector = '[data-register-close]';
    private readonly closeBtn: HTMLElement;
    private readonly showClass = 'show';

    public constructor() {
        this.registerModal = document.querySelector(this.registerModalSelector);
        this.openBtn = document.querySelector(this.openBtnSelector);
        this.closeBtn = document.querySelector(this.closeBtnSelector);
        if (this.registerModal) {
            this.listenModal();
        }
    }

    protected listenModal() {
        this.openBtn.addEventListener('click', () => {
            this.registerModal.classList.add(this.showClass);
        });
        this.closeBtn.addEventListener('click', () => {
            this.registerModal.classList.remove(this.showClass);
            window.scrollTo(0, 0);
        });
    }
}
