import * as yup from 'yup';
import axios, { AxiosError } from 'axios';
import {LoginModalComponent} from "../components/LoginModalComponent";
import {InfoModalComponent} from "../components/InfoModalComponent";

export class ProjectController {
  protected readonly formSelector = '.js-login-form';
  protected readonly forms: NodeListOf<HTMLFormElement>;
  protected readonly validationSchema: yup.ObjectSchema<LoginValidationSchema>;
  protected readonly formErrorSelector = ".js-form-input-error";
  protected readonly formError: HTMLElement;

  public constructor() {
    console.log('ProjectController initialized!');
    const loginModal = new LoginModalComponent;
    const infoModal = new InfoModalComponent;
    this.forms = document.querySelectorAll(this.formSelector);
    this.formError = document.querySelector(this.formErrorSelector);

    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (this.forms) {
      this.validationSchema = this.buildValidationSchema();
      this.addEventListeners();
    }
    this.setVh();
    window.addEventListener('resize', this.setVh);
  }

    protected setVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

  protected addEventListeners(): void {
    this.forms.forEach(form => {
        form.addEventListener('submit', e => this.handleSubmit(e, form));
    })
  }

  protected async handleValidation(form): Promise<yup.ValidationError[]> {
    return await this.validationSchema.validate({
      email: form.email.value,
      password: form.password.value,
    }, {
      abortEarly: false,
    }).then(_ => []).catch((e: yup.ValidationError) => e.inner);
  }

  protected displayValidationResults(errors: yup.ValidationError[], form): void {
    const self = this; // maintain `this` context
    Object.keys(this.validationSchema.fields).forEach(key => {
      // dunno why it breaks
      const input = form.querySelector(`[name="${key}"]`) as HTMLElement;
      const error = errors.find(e => e.path === key);
      const hasError = error !== undefined;
      // const errorElement = input.closest('.form-input').querySelector('.js-form-input__error');
      const errorElement = input.parentElement.querySelector('.js-form-input__error');
      input.parentElement.classList.toggle('has-error', hasError);
      errorElement.innerHTML = error ? error.errors[0] : '';
      errorElement.classList.toggle('active', !!error);
    });
  }

  protected async handleInput(event: InputEvent, form): Promise<void> {
    this.displayValidationResults(await this.handleValidation(form), form);
  }

  protected async handleSubmit(event: Event, form): Promise<void> {
    event.preventDefault();
    const errors = await this.handleValidation(form);
    this.displayValidationResults(errors, form);
    this.scrollToError(form);
    form.addEventListener('input', e => this.handleInput(e, form)); // Will only be added once.
    if (errors.length) {
      return;
    }

    // please refactor this
    const self = this;
    await new Promise<string>(resolve => {
      axios.request<LoginResponse>({
        method: 'POST',
        url: '/api/login',
        data: {
          username: form.email.value,
          password: form.password.value,
        } as LoginRequest,
      })
      .then(res => {
        window.location.href = '/';
      })
      .catch(err => {
        if (err.isAxiosError) {
          console.log(err.response);
          const res = err.response;
          let msg;
          if (res.data.error == 'invalid_grant') {
            msg = 'Credenziali errate. Riprova'
          } else {
            msg = res.data.message;
          }
          self.formError.innerText = msg;
        }
      })
    });
  }

  protected scrollToError(form): void {
    const firstError = form.querySelector('.has-error');
    firstError && firstError.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  protected buildValidationSchema(): yup.ObjectSchema<LoginValidationSchema> {
    yup.setLocale({
      mixed: {
        required: params => `Questo campo è obbligatorio!`,
      },
      string: {
        email: params => `Immetti un indirizzo email valido`,
        min: params => `Questo campo deve contenere almeno ${params.min} caratteri!`,
        max: params => `Questo campo deve contenere al massimo ${params.max} caratteri!`,
      },
    });

    return yup.object().shape({
      email: yup
        .string()
        .label('E-mail')
        .email()
        .required()
        .max(255),
      password: yup
        .string()
        .label('Password')
        .required()
        .max(255),
    });
  }
}

interface LoginValidationSchema {
  email: string;
  password: string;
}

interface LoginRequest {
  username: string;
  password: string;
}

interface LoginResponse {
  token_type: 'Bearer' | string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

interface LoginError {
  hint: string;
  error: string;
  message: string;
  error_description: string;
};
