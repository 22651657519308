export class AdminAreaController {
  table: HTMLElement;
  numBoxes: number;
  sliders: NodeListOf<HTMLElement>;
  arrowRight: HTMLElement;
  arrowLeft: HTMLElement;
  maxSlides: number;
  counter: number;
  transValue: number;

  get SELECTORS() {
    return {
      arrowRight: '.admin-table__arrow-right',
      arrowLeft: '.admin-table__arrow-left',
    }
  }

  get CLASSNAMES() {
    return {
      disabled: 'admin-table__arrow--disabled',
    }
  }

  public constructor() {
    this.slideRight = this.slideRight.bind(this);
    this.slideLeft = this.slideLeft.bind(this);
    this.table = document.querySelector('.admin-table-register');
    this.numBoxes = parseInt(this.table.dataset.boxes);
    this.sliders = this.table.querySelectorAll('.admin-table__slider');
    this.sliders.forEach(slider => {
      slider.style.gridTemplateColumns = `repeat(${this.numBoxes}, 95px)`;
      slider.style.transform = `translateX(0)`;
    });
    this.arrowRight = document.querySelector(this.SELECTORS.arrowRight);
    this.arrowLeft = document.querySelector(this.SELECTORS.arrowLeft);
    this.maxSlides = this.numBoxes -9;
    this.transValue = 0;
    this.counter = this.maxSlides;
    if (this.numBoxes > 9) {
      this.addSlider();
    }
  }

  addSlider() {
    this.arrowRight.classList.remove(this.CLASSNAMES.disabled);
    this.arrowRight.addEventListener('click', this.slideRight);    
  }

  slideRight() {
    this.transValue += 95;
    this.sliders.forEach(slider => {     
      slider.style.transform = `translateX(-${this.transValue}px)`;
    })
    this.arrowLeft.classList.remove(this.CLASSNAMES.disabled);
    this.counter -= 1;
    if (this.counter == 0) {
      this.arrowRight.removeEventListener('click', this.slideRight);
      this.arrowRight.classList.add(this.CLASSNAMES.disabled);
    }
    this.arrowLeft.addEventListener('click', this.slideLeft);
  }

  slideLeft() {
    this.transValue -= 95;
    this.sliders.forEach(slider => {
      slider.style.transform = `translateX(${this.transValue}px)`;
    })
    this.arrowRight.classList.remove(this.CLASSNAMES.disabled);
    this.counter += 1;
    if (this.counter == this.maxSlides) {
      this.arrowLeft.removeEventListener('click', this.slideLeft);
      this.arrowLeft.classList.add(this.CLASSNAMES.disabled);
    }
    this.arrowRight.addEventListener('click', this.slideRight);
  }
}