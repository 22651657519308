import {PodcastModal} from "../components/PodcastModal";
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';

export class ModulesController {
    private readonly podcastCardsSelector = '[data-podcast-id]';
    private readonly podcastCards: NodeListOf<HTMLElement>;
    private readonly closeBtnsSelector = '[data-podcast-close]';
    private readonly closeBtns: NodeListOf<HTMLElement>;
    private readonly podcastModalsSelector = '[data-podcast]';
    private readonly podcastModals: NodeListOf<HTMLElement>;
    private readonly modalsComps: PodcastModal[];
    private readonly stepSwitchSelector = '[data-step-switch]';
    private readonly stepSwitch: HTMLElement;
    private readonly driver: Driver;
    private arrowRightIcon: string;
    private arrowLeftIcon: string;
    private closeIcon: string;
    private bookIcon: string;
    private headphonesIcon: string;

    public constructor() {
        console.log('ModulesController initialized!');
        this.podcastCards = document.querySelectorAll(this.podcastCardsSelector);
        this.closeBtns = document.querySelectorAll(this.closeBtnsSelector);
        this.podcastModals = document.querySelectorAll(this.podcastModalsSelector);
        this.modalsComps = [];
        this.stepSwitch = document.querySelector(this.stepSwitchSelector);
        this.arrowLeftIcon = '';
        this.arrowRightIcon = '';
        this.closeIcon = '';
        this.bookIcon = '';
        this.headphonesIcon = '';
        this.setSvgs();
        this.driver = new Driver({
            allowClose: false,
            padding: 25,
            closeBtnText: `chiudi ${this.closeIcon}`,
            doneBtnText: 'Fine',
            prevBtnText: `${this.arrowLeftIcon} Precedente`,
            nextBtnText: `Prossimo ${this.arrowRightIcon}`,
            onReset: () => {this.setCookie('skipGuide', 1)},
        });
        if (this.stepSwitch) {
            this.guidedSteps();
        }
        if (this.podcastModals.length > 0) {
            this.podcastModals.forEach(modal => {
                let modalComp = new PodcastModal(modal);
                this.modalsComps.push(modalComp);
            })
        }
        if (this.podcastCards.length > 0 && this.podcastModals.length > 0) {
            this.listenOpenModal();
            this.listenCloseModal();
            this.listenExit();
        }
    }

    protected guidedSteps() {
        this.driver.defineSteps([
            {
                element: '[data-step-switch]',
                popover: {
                    title: 'Scegli come seguire i corsi',
                    description: `<div class="step-subtitle">${this.bookIcon} Segui i singoli corsi</div>
                        <div class="step-text">Guarda i video degli esperti e leggi gli approfondimenti.</div>
                        <div class="step-divider">oppure</div>
                        <div class="step-subtitle">${this.headphonesIcon} Ascolta i podcast</div>
                        <div class="step-text">Tutti i video e gli approfondimenti in formato audio.</div>`,
                    position: window.matchMedia('(min-width: 992px)').matches ? 'left-center' : 'top-center',
                },
            },
            {
                element: '[data-step-exam]',
                popover: {
                    title: 'Fai il test finale',
                    description: 'Metti alla prova le competenze apprese e ottieni il l’attestato di partecipazione.',
                    position: 'top-center',
                },
            }
        ]);
        window.addEventListener('load', () => {
            if (!this.checkCookie('skipGuide')) {
                this.driver.start();
            }
        })
    }

    protected checkCookie(name){
        let regex = new RegExp(name);
        let str = decodeURIComponent(document.cookie);
        let split = str.split(';');
        let res = split.findIndex(el => regex.test(el));
        return res > -1;
    }

    protected setCookie(cookie, val){
        document.cookie = `${cookie}=${val};SameSite=Lax;path=/`;
    }

    protected listenCloseModal() {
        this.closeBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.modalsComps.forEach(modal => {
                    modal.modal.classList.remove('active');
                    modal.stopEvents();
                })
            })
        })
    }

    protected listenOpenModal() {
        this.podcastCards.forEach(card => {
            card.addEventListener('click', () => {
                this.openModal(card.dataset.podcastId);
            })
        })
    }

    protected openModal(id) {
        this.modalsComps.map(modal => {
            if (modal.id === id) {
                modal.initValues();
                modal.modal.classList.add('active');
                modal.listenEvents();
            }
        })
    }

    protected listenExit() {
        window.addEventListener('beforeunload', () => {
            this.modalsComps.map(modal => {
                modal.saveTime();
            });
        })
    }

    protected setSvgs() {
        this.arrowRightIcon = '<i>' +
            '   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 9.077 15.326">\n' +
            '       <path d="M3109,374.934l6.956,6.956,6.956-6.956" transform="translate(-374.227 3123.619) rotate(-90)" fill="none" stroke="currentColor" stroke-width="2"/>\n' +
            '   </svg>' +
            '</i>';
        this.arrowLeftIcon = '<i>' +
            '   <svg xmlns="http://www.w3.org/2000/svg" width="9.077" height="15.326" viewBox="0 0 9.077 15.326">\n' +
            '       <path d="M3109,374.934l6.956,6.956,6.956-6.956" transform="translate(383.304 -3108.293) rotate(90)" fill="none" stroke="currentColor" stroke-width="2"/>\n' +
            '   </svg>' +
            '</i>';
        this.closeIcon = '<i>' +
            '   <svg xmlns="http://www.w3.org/2000/svg" width="25.414" height="25.414" viewBox="0 0 25.414 25.414">\n' +
            '       <g transform="translate(0.707 0.707)">\n' +
            '           <line x2="24" y2="24" fill="none" stroke="currentColor" stroke-width="2"/>\n' +
            '           <line x1="24" y2="24" fill="none" stroke="currentColor" stroke-width="2"/>\n' +
            '       </g>\n' +
            '   </svg>' +
            '</i>';
        this.bookIcon = '<i>' +
            '   <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">\n' +
            '        <g transform="translate(-1 -2)">\n' +
            '            <path d="M2,3H8a4,4,0,0,1,4,4V21a3,3,0,0,0-3-3H2Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
            '           <path d="M22,3H16a4,4,0,0,0-4,4V21a3,3,0,0,1,3-3h7Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
            '       </g>' +
            '   </svg>' +
            '</i>';
        this.headphonesIcon = '<i>' +
            '   <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 22.5 22.5">\n' +
            '       <g transform="translate(1 1)">\n' +
            '           <path d="M3,20.083V13.25a10.25,10.25,0,1,1,20.5,0v6.833" transform="translate(-3 -3)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
            '           <path d="M23.5,19.694a2.278,2.278,0,0,1-2.278,2.278H20.083a2.278,2.278,0,0,1-2.278-2.278V16.278A2.278,2.278,0,0,1,20.083,14H23.5Zm-20.5,0a2.278,2.278,0,0,0,2.278,2.278H6.417a2.278,2.278,0,0,0,2.278-2.278V16.278A2.278,2.278,0,0,0,6.417,14H3Z" transform="translate(-3 -1.472)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
            '       </g>' +
            '   </svg>' +
            '</i>';
    }
}
