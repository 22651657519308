import * as yup from "yup";
import {LoginCarouselComponent} from "../components/LoginCarouselComponent";
import {LoginVideoComponent} from "../components/LoginVideoComponent";
import {RegisterModalComponent} from "../components/RegisterModalComponent";
import {SkipModalComponent} from "../components/SkipModalComponent";

interface RegisterValidationSchema {
    name:                  string;
    last_name:             string;
    email:                 string;
    password:              string;
    password_confirmation: string;
    privacy:               boolean;
    newsletter:            boolean;
}

export class RegisterController {
    private readonly startBtnsSelector = '[data-info-open]';
    private readonly startBtns: NodeListOf<HTMLElement>;
    private readonly registerSectionSelector = '[data-register-modal]';
    private readonly registerSection: HTMLElement;
    private readonly path: string;
    form: HTMLFormElement;
    inputActions: NodeListOf<HTMLElement> | null;
    isAutoValidationEnabled: boolean;
    validationSchema: yup.ObjectSchema<RegisterValidationSchema>;

  constructor() {
    console.log('RegisterController initialized!');
    const carousel = new LoginCarouselComponent;
    const video = new LoginVideoComponent;
    const registerModal = new RegisterModalComponent;
    const skipModal = new SkipModalComponent;
    this.startBtns = document.querySelectorAll(this.startBtnsSelector);
    this.registerSection = document.querySelector(this.registerSectionSelector);
    this.path = window.location.pathname;
    // FORM
    this.form = document.querySelector('.js-register-form');
    this.inputActions = document.querySelectorAll(".js-form-input-action") as NodeListOf<HTMLElement> | null;
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.isAutoValidationEnabled = false;
    this.checkSkip(skipModal);
    if (this.form) {
      this.addListeners();
      this.validationSchema = this.buildValidationSchema();
    }
    if (this.inputActions) {
      this.inputActions.forEach((element) => {
        element.addEventListener("click", this.togglePassword)
      })
    }
    this.setVh();
    window.addEventListener('resize', this.setVh);
  }

    protected setVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    protected checkSkip(skipModal) {
        let skipCookieValue = '';
        const skipCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('skipIntro='));
        if (skipCookie) {
            skipCookieValue = skipCookie.split('=')[1];
        }
        if (skipCookieValue == 'true') {
            skipModal.skip();
        }
    }

    addListeners() {
        this.startBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                this.registerSection.scrollIntoView();
            });
        });
        this.form.addEventListener('input', this.handleInput);
        this.form.addEventListener('submit', this.handleSubmit);
    }

  togglePassword(e: Event) {
    let target = e.currentTarget as HTMLElement;
    let input = target.parentNode.querySelector("input") as HTMLInputElement | null;

    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  }

  async handleInput(event: Event): Promise<void> {
    if (this.isAutoValidationEnabled) {
      const errors: [] | yup.ValidationError = await this.handleValidation();
      this.displayValidationResults(errors);
    }
  }

  async handleValidation() {
      if (this.path === '/privacy-acceptance') {
          return await this.validationSchema.validate({
              name:                  (this.form.elements.namedItem('name') as HTMLInputElement).value,
              last_name:             this.form.last_name.value,
              email:                 this.form.email.value,
              username:              this.form.username.value,
              privacy:               this.form.privacy.checked,
              newsletter:            this.form.newsletter.checked,
          }, {
              abortEarly: false,
          }).then(_ => []).catch(e => e.inner);
      } else {
          return await this.validationSchema.validate({
              name:                  (this.form.elements.namedItem('name') as HTMLInputElement).value,
              last_name:             this.form.last_name.value,
              email:                 this.form.email.value,
              username:              this.form.username.value,
              password:              this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
              privacy:               this.form.privacy.checked,
              newsletter:            this.form.newsletter.checked,
          }, {
              abortEarly: false,
          }).then(_ => []).catch(e => e.inner);
      }
  }

  displayValidationResults(errors) {
    Object.keys(this.validationSchema.fields).forEach(key => {
      const input = this.form.querySelector(`[name="${key}"]`);
      const error = errors.find(e => e.path === key);
      const hasError = error !== undefined;
      const errorElement = input.closest(".form-input").querySelector('.js-form-input__error');
      input.parentElement.classList.toggle('has-error', hasError);

      errorElement.innerHTML = error ? error.errors[0] : '';
      errorElement.classList.toggle("active", error);
    });
  }

  async handleSubmit(event) {
      event.preventDefault();
    let errors = [];
    if (!this.isAutoValidationEnabled) {
      this.isAutoValidationEnabled = true;
      errors = await this.handleValidation();
      this.displayValidationResults(errors);
    }

    errors = await this.handleValidation();

    if (errors.length) {
      event.preventDefault();
      errors = await this.handleValidation();
      this.displayValidationResults(errors);

      const firstError = this.form.querySelector('.has-error');
      firstError && firstError.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      this.form.submit();
    }
  }

  buildValidationSchema(): yup.ObjectSchema<RegisterValidationSchema> {
    yup.setLocale({
      mixed: {
        required: params => `Questo campo è obbligatorio!`,
      },
      string: {
        min: params => `Questo campo deve contenere almeno ${params.min} caratteri!`,
        max: params => `Questo campo deve contenere al massimo ${params.max} caratteri!`,
      },
    });

    let schema;
    if (this.path === '/privacy-acceptance') {
        schema = yup.object().shape({
            name: yup
                .string()
                .label('Nome')
                .required()
                .min(2)
                .max(255),
            last_name: yup
                .string()
                .label('Cognome')
                .required()
                .min(2)
                .max(255),
            email: yup
                .string()
                .label('E-mail Personale')
                .email()
                .required()
                .max(255),
            username: yup
                .string()
                .label('Nickname')
                .required()
                .min(2)
                .max(255),
            privacy: yup
                .boolean()
                .label('Privacy acceptance')
                .oneOf([true], 'Si prega di accettare questi termini!'),
            newsletter: yup
                .boolean()
                .label('Privacy acceptance')
        });
    } else {
        schema = yup.object().shape({
            name: yup
                .string()
                .label('Nome')
                .required()
                .min(2)
                .max(255),
            last_name: yup
                .string()
                .label('Cognome')
                .required()
                .min(2)
                .max(255),
            email: yup
                .string()
                .label('E-mail Personale')
                .email()
                .required()
                .max(255),
            username: yup
                .string()
                .label('Nickname')
                .required()
                .min(2)
                .max(255),
            password: yup
                .string()
                .label('Password')
                .required()
                .min(8)
                .max(255),
            password_confirmation: yup
                .string()
                .label('Conferma Password')
                .required('Si prega di confermare la sua password.')
                .min(8)
                .max(255)
                .oneOf([yup.ref('password'), null], 'Le tue password non sono identiche.'),
            privacy: yup
                .boolean()
                .label('Privacy acceptance')
                .oneOf([true], 'Si prega di accettare questi termini!'),
            newsletter: yup
                .boolean()
                .label('Privacy acceptance')
        });
    }
    return schema;
  }
}
